import { useState } from 'react';

import { Nullable } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import { getValueCarOptions } from '@/modules/EvaluationRedemption/helpers';
import { useTypedSelector } from '@/store/store';
import { getCarDataItems } from '@/store/reducers/catalog';

import {
  CarSelectsOptions,
  CarSelectsValues,
  EvaluationRedemption,
} from './types';
import { defaultValues } from './constants';

export function useEvaluationRedemptionData(): Page<EvaluationRedemption> {
  return useCurrentPage<Page<EvaluationRedemption>>();
}

export const useValueCarOptions = (
  brand: Nullable<OptionType>
): Nullable<CarSelectsOptions> => {
  const rateCarData = useTypedSelector(getCarDataItems);

  return getValueCarOptions(rateCarData, brand);
};

export const useCarValues = (): [
  CarSelectsValues,
  (name: keyof CarSelectsValues, value: Nullable<OptionType>) => void
] => {
  const [values, setNewValues] = useState<CarSelectsValues>(defaultValues);

  const setValues = (
    name: keyof CarSelectsValues,
    value: Nullable<OptionType>
  ) => {
    if (name === 'brand') {
      setNewValues({ ...defaultValues, [name]: value });
    } else if (name === 'model') {
      setNewValues({ ...values, [name]: value, year: defaultValues.year });
    } else {
      setNewValues({ ...values, [name]: value });
    }
  };

  return [values, setValues];
};
