import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/components/Redesign/Button';
import { media } from '@/utils/mixin';
import Select from '@/components/Redesign/Select';

import { MainProps } from './types';

function Form({ buttonText, onSubmit, onChange, options, values }: MainProps) {
  return (
    <Component>
      <Select
        label="Марка"
        options={options?.brands ?? []}
        value={values.brand}
        selectOptions={{ isLabelInner: true }}
        onChange={(value) => onChange?.('brand', value)}
      />

      <Select
        label="Модель"
        options={options?.models ?? []}
        value={values.model}
        selectOptions={{
          isLabelInner: true,
          disabled: !values?.brand,
          disabledHint: 'Вы не можете выбрать "Модель" пока не выберите Марку.',
        }}
        onChange={(value) => onChange?.('model', value)}
      />

      <Select
        label="Год выпуска"
        options={options?.years ?? []}
        value={values.year}
        selectOptions={{
          isLabelInner: true,
          disabled: !values?.model,
          disabledHint:
            'Вы не можете выбрать "Год выпуска" пока не выберите Модель.',
        }}
        onChange={(value) => onChange?.('year', value)}
      />

      <ButtonWrapper>
        <Button
          disabled={!values.year}
          variant="contained"
          size="small"
          onClick={() => onSubmit?.(values)}
        >
          {buttonText}
        </Button>
      </ButtonWrapper>
    </Component>
  );
}

export default Form;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 240px;
  align-items: end;
  gap: 10px;

  ${media.tablet(css`
    align-self: stretch;
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

const ButtonWrapper = styled.div`
  ${media.tablet(css`
    margin-top: 10px;
  `)}
`;
