import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { useEmergencyManagerData } from '@/modules/EmergencyManager/hooks';
import {
  ContentContainer,
  tabletPadding,
  Title,
} from '@/modules/EmergencyManager/common.styles';
import AllBrandsArrow from '@/assets/svg/emergency-manager/allBrands-arrow.svg';
import BrandsModal from '@/components/Redesign/BrandsModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { colors } from '@/constants/theme';
import BrandsSlider from '@/components/Redesign/CarBrandsSlider/CarBrandsSlider';

function CarBrands() {
  const {
    templateFields: {
      programForTitle: title,
      programForItems: items,
      allBrendsMobileButtonTitle: buttonText,
    },
  } = useEmergencyManagerData();
  const openModal = useModal();

  if (!title || !items) {
    return null;
  }

  const handleOpenModal = () => {
    openModal(
      BrandsModal,
      {
        images: brands,
        title: title,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  const brands = items.map(({ image }) => image);

  return (
    <Component>
      <ContentContainer>
        <Header>
          <Title>{title}</Title>
          {buttonText && (
            <AllBrandsButton onClick={handleOpenModal}>
              <ButtonText>{buttonText}</ButtonText>
              <SvgWrapper>
                <AllBrandsArrow />
              </SvgWrapper>
            </AllBrandsButton>
          )}
        </Header>

        <BrandsSlider brands={brands} />
      </ContentContainer>
    </Component>
  );
}

export default CarBrands;

const Component = styled.section`
  margin-bottom: 60px;

  ${media.tablet(css`
    margin: 0 -${tabletPadding}px 40px 0;
  `)}
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 20px;
`;

const AllBrandsButton = styled.button`
  display: none;

  ${media.tablet(css`
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
    margin-left: 8px;
    justify-content: center;
    background: ${colors.gray1300};
  `)}
`;

const ButtonText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${colors.black};
`;

const SvgWrapper = styled.div`
  width: 15px;
  height: 15px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
