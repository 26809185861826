import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import {
  useCarValues,
  useEvaluationRedemptionData,
  useValueCarOptions,
} from '@/modules/EvaluationRedemption/hooks';
import { media } from '@/utils/mixin';
import {
  ContentContainer,
  Title,
} from '@/modules/EvaluationRedemption/common.styles';
import { CarSelectsValues } from '@/modules/EvaluationRedemption/types';
import EvaluationRedemptionModal from '@/components/modals/EvaluationRedemptionModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitRateCarOffline } from '@/services/leads/leads-service';

import Form from './components/Form';

function HeroForm() {
  const {
    templateFields: { formTitle, formButtonText },
  } = useEvaluationRedemptionData();
  const [values, setValues] = useCarValues();
  const options = useValueCarOptions(values.brand);
  const modal = useModal();

  if (!formTitle || !formButtonText) {
    return null;
  }

  const onSubmit = (values: CarSelectsValues) => {
    modal(
      EvaluationRedemptionModal,
      {
        title: 'Оценка и выкуп',
        selectedFields: {
          brand: values.brand?.value ?? '',
          model: values.model?.value ?? '',
          year: Number(values.year?.value) ?? 0,
        },
        onSubmit: async (values) => {
          try {
            await submitRateCarOffline({
              brand: values.brand,
              model: values.model,
              year: values.year,
              name: values.name,
              phone: values.phone,
            });
          } catch (error: any) {
            console.log(error);
          }
        },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <Title>{formTitle}</Title>

        <FormWrapper>
          <Form
            onSubmit={onSubmit}
            values={values}
            onChange={setValues}
            options={options}
            buttonText={formButtonText}
          />
        </FormWrapper>
      </ContentContainer>
    </Component>
  );
}

export default HeroForm;

const Component = styled.div`
  margin: 5px 0 40px;

  ${media.tablet(css`
    margin: 5px 0 0;
  `)}
`;

const FormWrapper = styled.div`
  margin-top: 10px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;
