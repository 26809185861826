import { Nullable } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import { getYearsOptions } from '@/utils/date';
import { CatalogCarsDataResponse } from '@/services/catalog/typings';

import { CarSelectsOptions } from './types';

export const getValueCarOptions = (
  data: CatalogCarsDataResponse[],
  brand: Nullable<OptionType>
): Nullable<CarSelectsOptions> => {
  if (!data || data.length === 0) {
    return null;
  }

  const brands: OptionType[] = data.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const models: OptionType[] =
    data
      .find(({ name }) => name === brand?.value)
      ?.models.map(({ name }) => ({ label: name, value: name })) ?? [];

  return {
    brands,
    models,
    years: getYearsOptions(),
  };
};
